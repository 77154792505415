$xs: 399px;
$sm: 599px;
$md: 991px;
$lg: 1439px;

@mixin bp($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: $xs) {
      @content;
    }
  } @else if $breakpoint == sm {
    @media (max-width: $sm) {
      @content;
    }
  } @else if $breakpoint == md {
    @media (min-width: #{($sm + 1)}) and (max-width: $md) {
      @content;
    }
  } @else if $breakpoint == lg {
    @media (min-width: #{($md + 1)}) {
      @content;
    }
  }
}