.blog {
	.blog-title {
	}

	.blog-posts {
		font-family: $quicksand-font;
		padding: 30px;

		.blog-title {
      text-decoration: underline;
		}
    
    .blog-description {
      font-style: italic;
    }

		.blog-post {
			padding: 30px;
			border-bottom: 1px solid #e5e5e5;
			.blog-post-title {
				font-size: 1.3rem;
			}

			.blog-post-meta {
				font-size: 1.1rem;
				margin-bottom: 15px;
			}

			.blog-post-body {
				font-size: 1.1rem;
				line-height: 1.5;
			}
		}
	}
}
