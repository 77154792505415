.about-us {
  background-color: #efefef;
  padding-bottom: 60px;

  .row {
    padding: 50px 0;
  }

  p {
    text-align: left;

    @include bp(md) {
      text-align: center;
    }
  }

  img {
    max-width: 100%;

    @include bp(sm) {
      margin: 0 0 30px 0;
    }
  }

  .boe-content {
    @include bp(md) {
      flex-direction: column-reverse;
      padding-top: 0;
    }

    img {
      width: 100%;
    }
  }

  .boe-content,
  .leading-lady {
    .title {
      font-size: $large;
      margin-top: 0;
      
      @include bp(sm) {
        margin-top: 20px;
      }
    }
  }

  .vision-mission {
    .title {
      margin-top: 0;

      span {
        font-size: $large;
      }
    }

    p {
      text-align: center;
    }
  }
}