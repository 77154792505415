@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500|Oswald:200,400&display=swap);
.title {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 2.25rem;
  font-weight: bold;
  margin-top: 60px;
  position: relative;
  text-transform: uppercase;
}
.title span {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.underline {
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 20px;
  position: relative;
  width: 110px;
}

.line:before {
  background: #d33;
  bottom: 0;
  content: "";
  height: 1px;
  position: absolute;
  right: 0;
  width: 110px;
}
.line:after {
  background: #d33;
  bottom: 5px;
  content: "";
  height: 1px;
  position: absolute;
  right: 16px;
  width: 75px;
}

.primary-button {
  background: #fff;
  border: 1px solid #2f2f2f;
  color: #2f2f2f;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  outline: 0;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  transition: 0.4s ease;
}
.primary-button:hover {
  background: #2f2f2f !important;
  color: #fff;
}
.primary-button > span {
  display: inline-block;
}

.button-full {
  width: 100%;
}

.card-content {
  text-align: center;
  white-space: pre-line;
}
.card-content .card-title {
  font-size: 1.75rem;
  font-weight: bold;
}
.card-content img {
  height: 300px;
  max-width: 100%;
  object-fit: cover;
}

.top-nav {
  background-color: #d33;
}
@media (max-width: 599px) {
  .top-nav .ml-auto {
    margin: 0 !important;
  }
}
.top-nav .link {
  color: #fff;
  font-size: 0.75rem;
  margin-right: 3rem;
}
@media (max-width: 599px) {
  .top-nav .link {
    text-align: left;
    margin: 5px 0;
  }
}
.top-nav .link svg,
.top-nav .link span,
.top-nav .link a {
  margin-right: 0.5rem;
}
.top-nav .link.social {
  margin: 0;
}
@media (max-width: 599px) {
  .top-nav .link.social {
    margin: 5px 0;
  }
}
.top-nav .link.social svg {
  font-size: 0.75rem;
  margin-left: 1rem;
}

.sidebar .menu-text {
  margin-right: 5px;
}
@media (max-width: 599px) {
  .sidebar .menu-text {
    display: none;
  }
}

.logo {
  line-height: 1;
  margin-right: 2rem;
}

.navbar-brand {
  font-size: 1.75rem;
  font-weight: 700;
}

.tagline {
  text-align: left;
}
.tagline span {
  color: #fff;
  font-size: 1rem;
}

.hero-container .hero-image img {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  object-fit: cover;
}

.about-us {
  background-color: #efefef;
  padding-bottom: 60px;
}
.about-us .row {
  padding: 50px 0;
}
.about-us p {
  text-align: left;
}
@media (min-width: 600px) and (max-width: 991px) {
  .about-us p {
    text-align: center;
  }
}
.about-us img {
  max-width: 100%;
}
@media (max-width: 599px) {
  .about-us img {
    margin: 0 0 30px 0;
  }
}
@media (min-width: 600px) and (max-width: 991px) {
  .about-us .boe-content {
    flex-direction: column-reverse;
    padding-top: 0;
  }
}
.about-us .boe-content img {
  width: 100%;
}
.about-us .boe-content .title,
.about-us .leading-lady .title {
  font-size: 1.75rem;
  margin-top: 0;
}
@media (max-width: 599px) {
  .about-us .boe-content .title,
  .about-us .leading-lady .title {
    margin-top: 20px;
  }
}
.about-us .vision-mission .title {
  margin-top: 0;
}
.about-us .vision-mission .title span {
  font-size: 1.75rem;
}
.about-us .vision-mission p {
  text-align: center;
}

.blog .blog-posts {
  font-family: "Quicksand", sans-serif;
  padding: 30px;
}
.blog .blog-posts .blog-title {
  text-decoration: underline;
}
.blog .blog-posts .blog-description {
  font-style: italic;
}
.blog .blog-posts .blog-post {
  padding: 30px;
  border-bottom: 1px solid #e5e5e5;
}
.blog .blog-posts .blog-post .blog-post-title {
  font-size: 1.3rem;
}
.blog .blog-posts .blog-post .blog-post-meta {
  font-size: 1.1rem;
  margin-bottom: 15px;
}
.blog .blog-posts .blog-post .blog-post-body {
  font-size: 1.1rem;
  line-height: 1.5;
}

.discography .disc {
  display: flex;
  justify-content: center;
}
.discography .discography-reviews {
  margin-bottom: 100px;
}
.discography .discography-reviews .carousel {
  box-shadow: 0.2rem 0.2rem 2rem gainsboro;
  display: flex;
  align-items: center;
  transition: height 0.2s ease-in-out;
}
.discography .discography-reviews .carousel-control-prev {
  margin-left: 20px;
}
.discography .discography-reviews .carousel-control-next {
  margin-right: 20px;
}
.discography .discography-reviews .carousel-control-prev,
.discography .discography-reviews .carousel-control-next {
  background-color: grey;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: auto;
  left: auto;
  bottom: auto;
  padding: 10px;
  border-radius: 4px;
  color: white;
}
@media (max-width: 599px) {
  .discography .discography-reviews .carousel-control-prev,
  .discography .discography-reviews .carousel-control-next {
    display: none;
  }
}
.discography .discography-reviews .review {
  box-shadow: none;
  margin: 0 auto;
  width: 70%;
}
@media (max-width: 599px) {
  .discography .discography-reviews .review {
    padding: 15px;
    width: 100%;
  }
}
.discography .discography-reviews .review .quote {
  font-size: 1.5rem;
}
@media (max-width: 599px) {
  .discography .discography-reviews .review .quote {
    font-size: 1.2rem;
  }
}
.discography .discography-reviews .review .quote::before {
  content: '"';
}
.discography .discography-reviews .review .quote::after {
  content: '"';
}
.discography .discography-reviews .review .citation {
  margin-top: 20px;
}
@media (max-width: 599px) {
  .discography .discography-reviews .review .citation {
    padding-bottom: 20px;
  }
}
.discography .discography-reviews .carousel-indicators li {
  background-color: rgb(185, 170, 170);
}
.discography .discography-reviews .carousel-indicators li.active {
  background-color: rgb(100, 100, 100);
}

.alabaster-moments {
  padding-bottom: 60px;
}
.alabaster-moments .moment-content .title span {
  font-size: 1.75rem;
}
@media (max-width: 599px) {
  .alabaster-moments .moment-content .title {
    margin-top: 0;
  }
}

.projects .project-container {
  border-bottom: 0.5px solid lightgrey;
  padding: 60px 0;
}
.projects .project-container:first-child {
  padding-top: 10px;
}
.projects .project-container:last-child {
  border-bottom: none;
}
.projects .role {
  font-weight: bold;
  color: gray;
  margin: 0 auto 40px auto;
  width: 80%;
}
.projects .project {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.projects .project .project-details {
  margin: 20px;
}
.projects .project .project-details .project-title {
  color: grey;
  text-transform: uppercase;
  font-size: 1.4rem;
  margin: 5px auto;
  max-width: 70%;
  line-height: 1.3em;
}
.projects .project .project-details img {
  height: 250px;
  object-fit: cover;
  width: 250px;
}
.projects .project .project-details .info-section {
  color: grey;
  font-size: 1.2rem;
}
.projects .project .project-details .info-section .project-links {
  border-bottom: 1px dashed lightgrey;
}

.event-block {
  background-color: #efefef;
  padding-bottom: 60px;
}

.music-and-society .page-heading {
  margin-bottom: 20px;
}
.music-and-society .spanish-title {
  margin-top: 0;
  margin-bottom: 30px;
}
.music-and-society .text-container {
  -webkit-columns: 20px 2;
          columns: 20px 2;
  font-size: 1.3rem;
  color: #2f2f2f;
  padding-top: 20px;
}
@media (max-width: 599px) {
  .music-and-society .text-container {
    -webkit-columns: unset;
            columns: unset;
  }
}
.music-and-society .youtube-video-container {
  margin: 20px auto;
}
.music-and-society .youtube-video-container .youtube-video {
  padding: 0;
}
.music-and-society .youtube-video-container .youtube-video iframe {
  padding: 10px;
  width: 100%;
}
.music-and-society .know-more {
  font-size: 1.3rem;
}
.music-and-society .know-more a {
  border-bottom: 1px dashed #fff;
}

.press-links {
  padding-bottom: 60px;
}
.press-links .press-link {
  padding: 10px 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.press-links .press-link-title {
  border-bottom: 0.5px dashed slategrey;
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.press-links .press-link-source {
  color: slategray;
  font-size: 1rem;
}

.online-music-courses img {
  margin-top: 40px;
}
.online-music-courses .title {
  margin-bottom: 60px;
}

.latest-updates {
  padding-bottom: 60px;
}
.latest-updates .update-row {
  justify-content: center;
  margin-top: 40px;
}
.latest-updates .project-details {
  text-align: center;
}
.latest-updates .description {
  font-family: "Quicksand", sans-serif;
  width: 400px;
}

.project-details-flex {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.know-more {
  background-color: #d33;
  color: #fff;
  padding: 60px 0;
}
.know-more .row {
  width: 100%;
  text-align: center;
}
.know-more .title {
  display: block;
  font-size: 1.75rem;
  font-weight: normal;
  margin: 0;
  margin-bottom: 60px;
}
.know-more .know-more-content {
  text-align: left;
}
@media (min-width: 600px) and (max-width: 991px) {
  .know-more .know-more-content {
    text-align: center;
  }
}
@media (max-width: 599px) {
  .know-more .know-more-button {
    margin: 20px 0;
  }
}
.know-more .primary-button {
  background-color: rgba(47, 47, 47, 0.7);
}
.know-more .primary-button span {
  color: #fff;
}
.know-more .primary-button:hover {
  background-color: #232323 !important;
}

.footer {
  background-color: #2f2f2f;
  color: #fff;
  padding: 5px 0;
}
.footer .row {
  width: 100%;
}
.footer .copyright {
  text-align: left;
}
.footer .footer-links {
  text-align: right;
}
.footer .footer-links ol.breadcrumb {
  background: inherit;
  float: right;
  margin: 0;
}
@media (max-width: 599px) {
  .footer .footer-links ol.breadcrumb {
    float: left;
  }
}
.footer .footer-links ol.breadcrumb li a {
  color: #d33 !important;
}

p {
  margin-bottom: 0 !important;
}

.oswald {
  font-family: "Oswald", sans-serif;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.font-light {
  font-weight: 300;
  letter-spacing: 0.3rem;
}

.page-heading {
  font-family: "Oswald", sans-serif;
  text-align: center;
  font-size: 3rem;
  margin-top: 60px;
  text-transform: uppercase;
  color: grey;
  letter-spacing: 0.3rem;
}

.page-subheading {
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-size: 1.75rem;
  color: grey;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 1rem 2rem;
  font-size: 1.3rem;
  color: gray;
  z-index: 200;
}

.header-home {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 1rem 2rem;
  font-size: 1.3rem;
  color: white;
  background-color: none;
  z-index: 200;
}

.nav-hidden {
  display: none;
}

.nav-visible {
  position: fixed;
  width: 20rem;
  height: 100%;
  background: white;
  top: 0;
  right: 0;
  padding: 2rem 2rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  box-shadow: 1rem 1rem 2rem gray;
  overflow-y: auto;
  z-index: 250;
}

.list-even {
  display: flex;
  flex-direction: column;
  color: gray;
}
.list-even .nav-links {
  border-bottom: 0.25px solid lightgrey;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.1em;
}
.list-even .nav-links a {
  display: block;
  padding: 1em 0;
}
.list-even li:last-child {
  margin-top: 2em;
}

.contact {
  margin-left: 2rem;
  height: 3rem;
  background-color: mediumaquamarine;
}

.close-nav {
  align-self: flex-end;
  color: gray;
  position: relative;
  cursor: pointer;
}

.hero-name {
  font-size: 4rem;
  letter-spacing: 0.3rem;
}

.hero-text {
  font-size: 1.5rem;
  font-weight: 300;
}

.bio-container {
  color: grey;
}
.bio-container > h3 {
  text-align: center;
}
.bio-container .bio-text {
  font-size: 1rem;
  color: slategrey;
}
@media (min-width: 992px) {
  .bio-container .bio-section:nth-child(2n) .bio-info {
    order: 2;
  }
}
@media (max-width: 599px) {
  .bio-container .bio-section .bio-info {
    order: 2;
  }
}
@media (min-width: 600px) and (max-width: 991px) {
  .bio-container .bio-section .bio-info {
    order: 2;
  }
}
.bio-container.reviews-and-collabs .bio-info {
  order: 0;
}
@media (max-width: 599px) {
  .bio-container .bio-image {
    margin-bottom: 40px;
  }
}
@media (min-width: 600px) and (max-width: 991px) {
  .bio-container .bio-image {
    margin: 0 auto 40px;
    max-width: 70%;
  }
}
.bio-container .review {
  color: #000;
}

.bio-section {
  margin: 80px 0;
}
@media (max-width: 599px) {
  .bio-section {
    margin: 60px 0;
  }
}
@media (min-width: 600px) and (max-width: 991px) {
  .bio-section {
    margin: 40px 0;
  }
}

.polaroid {
  padding: 3rem;
  box-shadow: 0.2rem 0.2rem 2rem gainsboro;
}
.polaroid img {
  border-style: none;
  width: 100%;
  height: initial;
  object-fit: contain;
}

.polaroid-container {
  display: none;
}

.review {
  width: 30%;
  padding: 3rem;
  box-shadow: 0.2rem 0.2rem 2rem gainsboro;
}

.lg-hidden {
  display: none;
}

.discs {
  width: 100%;
  padding: 5rem;
  display: flex;
  justify-content: center;
}

.album {
  width: -webkit-min-content;
  width: min-content;
  padding: 2rem;
  margin-right: 2rem;
}
.album > img {
  box-shadow: 2rem 2rem 0 lightcyan;
  object-fit: cover;
  object-position: left;
}

table {
  border-collapse: collapse;
  width: 25%;
}

tr {
  transition: background-color 0.5s;
}
tr:hover {
  background-color: lightcyan;
}

th {
  font-weight: 400;
  font-size: 1.5rem;
  padding: 1.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

td {
  font-weight: 400;
  font-size: 1.5rem;
  padding: 1.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.sm-links {
  text-align: center;
  color: gray;
  background-color: #fbfafa;
  padding: 3rem 0rem;
}

.sm-icon {
  padding: 0rem 0rem;
  margin: 0rem 1rem;
}

@media only screen and (max-width: 1200px) {
  .nav-visible {
    width: 80%;
  }
  .hero-name {
    font-size: 3rem;
    letter-spacing: 0.3rem;
  }
  .hero-text {
    font-size: 1rem;
    font-weight: 300;
  }
  .polaroid {
    padding: 1rem;
  }
  .polaroid-container {
    display: flex;
    justify-content: center;
  }
  .review {
    width: 100%;
  }
  .sm-hidden {
    display: none;
  }
  .lg-hidden {
    display: block;
  }
  .discs {
    justify-content: space-evenly;
  }
}
.Page-grid {
  display: grid;
  /* grid-template-rows: repeat(2, 1fr); */
  grid-auto-rows: minmax(330px, -webkit-max-content);
  grid-auto-rows: minmax(330px, max-content);
  /* grid-template-columns: repeat(12, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
  /* grid-auto-flow: row; */
  grid-gap: 20px;
  gap: 20px;
  padding: 30px 60px;
  /* 	padding: 0 60px; */
  /* 	column-gap: 60px; */
}

.Page-grid > * {
  /* grid-column: 5/9; */
  /* 	width: 29vw; */
  /* aspect-ratio: 1/1; */
  /* height: fit-content; */
  /* align-self: center; */
  /* 	margin: 12px 0; */
}

.Timeline-card-container {
  border: 1px solid #e4e4e4;
  padding-bottom: 6px;
  padding: 10px;
  border-radius: 0.6rem;
  margin-bottom: 20px;
  /* min-height: 320px; */
  /* 	height: fit-content; */
}

.Timeline-user-header {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 14px;
  color: #1f1f1f;
  margin-bottom: 10px;
}

.Timeline-user-header > img {
  width: 40px;
  height: 40px;
  background-color: azure;
  border-radius: 50px;
}

.Timeline-form {
  width: clamp(300px, 25vw, 600px);
}

.Timeline-form-input:focus {
  border: none !important;
}

.Latest-release-card-container {
  width: clamp(300px, 50%, 600px);
  margin: 0 0 32px 60px;
}

.Latest-release-card {
  display: grid;
  /* 	grid-template-rows: repeat(4, auto); */
  grid-template-columns: 150px 1fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 5px;
  row-gap: 5px;
  align-self: center;
}

.Latest-release-card-description {
  font-size: 14px;
  grid-column: 2/3;
  grid-row: 2/4;
}

.Latest-release-card-image {
  grid-column: 1/2;
  grid-row: 1/3;
}

.Latest-release-card-title {
  grid-column: 2/3;
  grid-row: 1/1;
}

@media only screen and (max-width: 700px) {
  .Page-grid {
    grid-template-columns: repeat(auto-fill, 100%);
    /* grid-template-rows: repeat(auto-fit, fit-content); */
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    padding: 30px 20px;
  }
  /*.Page-grid>*{

  	width: 90%;
  	height: fit-content;
  	justify-self: center;
  } */
  .Latest-release-card-container {
    width: 100%;
    padding: 20px;
    margin: 0 auto 32px auto;
  }
  .Latest-release-card {
    display: block;
  }
  .Latest-release-card-description {
    margin-bottom: 20px !important;
  }
  .discs {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .album {
    width: -webkit-min-content;
    width: min-content;
    padding: 2rem;
    margin-right: 0;
  }
  .sm-icon {
    padding: 0rem 0rem;
    margin: 0.5rem 0.5rem;
  }
  table {
    width: 80%;
  }
  th {
    font-size: 1rem;
  }
  td {
    font-size: 1rem;
  }
}
body {
  font-family: "Quicksand", sans-serif;
}

ul {
  list-style: none;
}

.row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

button {
  background: none;
  outline: none;
  border: none;
  font-family: inherit;
  color: inherit;
  font-weight: 500;
  transition: color 0.5s;
}
button:focus {
  outline: none;
}
button:hover {
  text-decoration: none;
  color: mediumaquamarine;
}

a {
  color: inherit;
  transition: color 0.5s;
}
a:hover {
  text-decoration: none;
  color: mediumaquamarine;
}
