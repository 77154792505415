.projects {

  .project-container {
    border-bottom: .5px solid lightgrey;
    padding: 60px 0;

    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
	.role {
		font-weight: bold;
		color: gray;
    margin: 0 auto 40px auto;
    width: 80%;
	}

	.project {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
		.project-details {
			margin: 20px;

			.project-title {
				color: grey;
				text-transform: uppercase;
				font-size: 1.4rem;
        margin: 5px auto;
        max-width: 70%;
        line-height: 1.3em;
			}

			img {
				height: 250px;
				object-fit: cover;
				width: 250px;
			}

			.info-section {
				color: grey;
				font-size: 1.2rem;

        .project-links {
          border-bottom: 1px dashed lightgrey;
        }
			}
		}
	}
}
