.know-more {
  background-color: $red;
  color: $white;
  padding: 60px 0;

  .row {
    width: 100%;
    text-align: center;
  }

  .title {
    display: block;
    font-size: $large;
    font-weight: normal;
    margin: 0;
    margin-bottom: 60px;
  }

  .know-more-content {
    text-align: left;

    @include bp(md) {
      text-align: center;
    }
  }

  .know-more-button {
    @include bp(sm) {
      margin: 20px 0;
    }
  }

  .primary-button {
    background-color: rgba(47, 47, 47, .7);

    span {
      color: $white;
    }

    &:hover {
      background-color: $dark-grey !important;
    }
  }
}
