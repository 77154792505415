.hero-container {
  .hero-image {
    img {
      filter: brightness(50%);
      height: 100vh;
      width: 100vw;
      max-width: 100%;
      object-fit: cover;
    }
  }
}
