.latest-updates {
  padding-bottom: 60px;

  .update-row {
    justify-content: center;
    margin-top: 40px;
  }
  
  .project-details {
    text-align: center;
  }
  
  .description {
    font-family: $quicksand-font;
    width: 400px;
  }
}

.project-details-flex{
display: flex;
gap: 20px;
}