.sidebar {
  .menu-text {
    margin-right: 5px;

    @include bp(sm) {
      display: none;
    }
  }
}

.logo {
  line-height: 1;
  margin-right: 2rem;
}

.navbar-brand {
  font-size: $large;
  font-weight: 700;
}

.tagline {
  text-align: left;

  span {
    color: $white;
    font-size: 1rem;
  }
}
