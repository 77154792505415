.music-and-society {
	.page-heading {
		margin-bottom: 20px;
	}
	.spanish-title {
		margin-top: 0;
		margin-bottom: 30px;
	}

	.text-container {
		columns: 20px 2;
		font-size: 1.3rem;
		color: $light-grey;
		padding-top: 20px;

		@include bp(sm) {
			columns: unset;
		}
	}

	.youtube-video-container {
		margin: 20px auto;

		.youtube-video {
			padding: 0;

			iframe {
				padding: 10px;
				width: 100%;
			}
		}
	}

	.know-more {
		font-size: 1.3rem;

    a {
      border-bottom: 1px dashed $white;
    }
	}
}
