.card-content {
  text-align: center;
  white-space: pre-line;

  .card-title {
    font-size: $large;
    font-weight: bold;
  }

  img {
    height: 300px;
    max-width: 100%;
    object-fit: cover;
  }
}
