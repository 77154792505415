.press-links {
  padding-bottom: 60px;

	.press-link {
		padding: 10px 0;
    width: fit-content;
	}
	.press-link-title {
		border-bottom: 0.5px dashed slategrey;
    font-weight: bold;
    width: fit-content;
	}

	.press-link-source {
		color: slategray;
		font-size: 1rem;
	}
}
