.top-nav {
  background-color: $red;

  .ml-auto {
    @include bp(sm) {
      margin: 0 !important;
    }
  }

  .link {
    color: $white;
    font-size: $small;
    margin-right: 3rem;

    @include bp(sm) {
      text-align: left;
      margin: 5px 0;
    }

    svg,
    span,
    a {
      margin-right: .5rem;
    }

    &.social {
      margin: 0;

      @include bp(sm) {
        margin: 5px 0;
      }

      svg {
        font-size: $small;
        margin-left: 1rem;
      }
    }
  }
}
