.discography {
  .disc {
    display: flex;
    justify-content: center;
  }
  .discography-reviews {
    margin-bottom: 100px;

    .carousel {
      box-shadow: 0.2rem 0.2rem 2rem gainsboro;
      display: flex;
      align-items: center;
      transition: height 0.2s ease-in-out;
    }

    .carousel-control-prev {
      margin-left: 20px;
    }

    .carousel-control-next {
      margin-right: 20px;
    }

    .carousel-control-prev,
    .carousel-control-next {
      background-color: grey;
      height: fit-content;
      width: fit-content;
      top: auto;
      left: auto;
      bottom: auto;
      padding: 10px;
      border-radius: 4px;
      color: white;

      @include bp(sm) {
        display: none;
      }
    }

    .review {
      box-shadow: none;
      margin: 0 auto;
      width: 70%;

      @include bp(sm) {
        padding: 15px;
        width: 100%;
      }

      .quote {
        font-size: 1.5rem;

        @include bp(sm) {
          font-size: 1.2rem;
        }

        &::before {
          content: '"';
        }

        &::after {
          content: '"';
        }
      }

      .citation {
        margin-top: 20px;

        @include bp(sm) {
          padding-bottom: 20px;
        }
      }
    }

    .carousel-indicators {
      li {
        background-color: rgb(185, 170, 170);

        &.active {
          background-color: rgb(100, 100, 100);
        }
      }
    }
  }
}
