.alabaster-moments {
  padding-bottom: 60px;
  
  .moment-content {
    .title {
      span {
        font-size: $large;
      }

      @include bp(sm) {
        margin-top: 0;
      }
    }
  }
}